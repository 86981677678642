export const ROUTE = {
  MAIN: "/",
  RESULT: "/result",
  RESULT_MY_LIST: "/result/mylist",
  RESULT_ALL_LIST: "/result/alllist",
  DETAIL: "/detail",
  ERROR: "/error",
  PESTICIDES: "/pesticides",
  FERTILIZERS: "/fertilizers",
  PRODUCTS: "/products",
  CROPMAP: "/cropmap",
  RESULT_AROUND_LIST: "/result/around-list",
  RESULT_MONTH_WARNING: "/result/monthwarning",
  ADDRESS: "/address",
};
