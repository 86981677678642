import React from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

type Information = {
  /**
   * 배경 색상
   * (#ABCDEF 또는 bg-blue-50 형태로 전달)
   */
  bgColor?: string;
  /**
   * 텍스트 및 아이콘 색상
   * (#ABCDEF 또는 bg-blue-50 형태로 전달)
   */
  textColor?: string;
  /**
   * 안내 문구
   */
  text: string;
};

export const Information = ({ bgColor = "bg-blue-50", textColor = "text-blue-500", text = "" }: Information) => {
  const bgC =
    bgColor?.indexOf("#") > -1 && bgColor?.indexOf("bg") < 0 ? "bg-[" + bgColor?.toUpperCase() + "]" : bgColor;
  const txC =
    textColor?.indexOf("#") > -1 && textColor?.indexOf("text") < 0
      ? "text-[" + textColor?.toUpperCase() + "]"
      : textColor;
  return (
    <>
      <div id="information" className={`mb-3 flex flex-wrap rounded-xl ${bgC} p-4 ${txC}`}>
        <div className="flex basis-1/12 justify-center pt-0.5">
          <InformationCircleIcon className={`h-4 w-4 ${txC}`} />
        </div>
        <div className="flex basis-10/12 flex-col">
          <div className={`flex-1 text-sm font-medium`}>{text}</div>
        </div>
        <div className="flex-1"></div>
      </div>
    </>
  );
};
