import { useRouter } from "next/router";
import Image from "next/image";
import { ROUTE } from "@constants/route";
import Header from "./index";

const HeaderV3 = ({ title, title2, callBacks }: Header) => {
  const router = useRouter();

  return (
    <>
      <div id="header" className="fixed z-30 m-auto flex h-[56px] w-full max-w-xl bg-white text-[#1F2024]">
        <a
          className="h-[56px] shrink-0 basis-[52px] flex items-center justify-center cursor-pointer"
          id="backButton"
          onClick={(e: any) => {
            callBacks(e, "header");
          }}
        >
          <Image src="/icons/left-contents.svg" width="32px" height="32px" alt="backButton"></Image>
        </a>
        <div className="flex w-0 grow flex-nowrap justify-center self-center text-center text-[19px] font-bold mr-14">
          <div className="text-ellipsis overflow-hidden whitespace-nowrap">
            {title}
            {!!title2 && <span>&nbsp;</span>}
          </div>
          {!!title2 && <div className=" whitespace-nowrap shrink-0">{title2}</div>}
        </div>
        {/*{router.pathname === "/result/[resultId]/detail/[detailId]" && (*/}
        {/*  <a*/}
        {/*    className="h-14 w-20 cursor-pointer pt-4 text-center"*/}
        {/*    id="shareButton"*/}
        {/*    onClick={(e: any) => {*/}
        {/*      callBacks(e, "share");*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <Image src="/icons/share.svg" width="24px" height="24px" alt="shareButton"></Image>*/}
        {/*  </a>*/}
        {/*)}*/}
      </div>
    </>
  );
};

export default HeaderV3;
