import { checkCondition, checkVersion, QA_FLAG_DEV, QA_FLAG_STAGING } from "@vhows/util";
import HeaderV1 from "./header-v1";
import HeaderV2 from "./header-v2";
import HeaderV3 from "./header-v3";

type Header = {
  title?: string;
  title2?: string;
  bodyScroll?: boolean;
  callBacks: Function;
};

function Header({ ...props }: Header) {
  return checkCondition([
    [checkVersion("3.2.0", QA_FLAG_STAGING), <HeaderV3 key={"HeaderV3"} {...props} />],
    [checkVersion("2.0.0", QA_FLAG_STAGING), <HeaderV2 key={"HeaderV2"} {...props} />],
    [<HeaderV1 key={"HeaderV1"} {...props} />],
  ]);
}

export default Header;
