import { atom } from "jotai";
import { atomWithImmer } from "jotai-immer";
import { DiseaseAnalysis, DiseaseAnalysisError, DiseaseAnalysisMonthly, Product } from "service/vhows";
import { checkVersion, Location, QA_FLAG_STAGING } from "@vhows/util";

export const userAtom = atom<User>({ id: 0, name: "" });
export const scrollTopAtom = atom(false);
export const loadingAtom = atom<boolean>(false);
export const loadingOnAtom = atom<boolean>(true);
export const captureAtom = atom<string>("");
export const compareIndexAtom = atom<number>(0);
export const compareImagesAtom = atom<string[]>([]);
export const detailIndexAtom = atom<number>(-1);
/** 레이아웃 헤더 타이틀 */
export const headerText = atom<string>(""); // ellipsis 속성이 있음
export const headerText2 = atom<string>(""); // ellipsis 속성이 없음
/** plantix 응답값 */
export const plantixAtom = atom<DiseaseAnalysis | DiseaseAnalysisError | null>(null);
/** 이전 진단 내역 */
export const prevHistoryAtom = atom<DiseaseAnalysis[]>([]);
/** 농진청 정보 - 서버리스호출 (v1.0.0) */
export const ncpmsAtom = atom<(NcpmsSickResponse | NcpmsInsectResponse | NcpmsVirusResponse | null)[] | null>(null);
/** 농진청 정보 유무값 (v1.0.2) */
export const ncpmsInfoAtom = atom<number[]>([]);
/** 농진청 정보 (단일) (v1.0.2)*/
export const ncpmsDetailAtom = atom<NcpmsDetailResponse | null>(null);
/** 농진청 정보 (다수) - 자재DB 호출 (v2.0.0) */
export const ncpmsDetailListAtom = atom<NcpmsDetailResponseList>([]);
/** 자세히 보기 버튼 visible 여부 */
export const ncpmsDetailVisibleAtom = atom<boolean[]>([]);
/** 상세 결과 (v3.2.0)*/
export const resultDetailAtom = atom<ResultDetail | null>(null);

/** ----- 농약 관련 atom ----- */
/** 추천 농약 리스트 */
export const pesticidesDataListAtom = atom<PesticidesDataList>([]);
/** 추천 농약 리스트 전체 */
export const pesticidesDataListAllAtom = atomWithImmer<PesticidesDataList>([]);
/** 선택한 농약 기본 정보 */
export const selectedPesticedeAtom = atom<Pesticides | null>(null);
/** 농약 상세 정보 */
export const pesticideInfoAtom = atom<PesticideInfo | null>(null);
/** 농약 상세 유입 루트 추적용 (gtm) */
export const pesticidePageSourceAtom = atom<string>("fromEtc");

/** ----- 비료 관련 atom ----- */
/** 추천 비료 리스트 (결과 페이지) */
export const fertilizersDataListAtom = atom<FertilizersDataList>([]);
/** 추천 비료 리스트 전체 (리스트 페이지) */
export const fertilizersDataListAllAtom = atom<FertilizersDataList>([]);
/** 선택한 비료 기본 정보 */
export const selectedFertilizerAtom = atom<Fertilizers | null>(null);
/** 비료 상세 정보 */
export const fertilizerInfoAtom = atom<FertilizerInfo | null>(null);
/** 비료 상세 정보 유입 경로 저장, gtm용 */
export const fertilizerDetailSourceAtom = atom<string>("from_etc");

/** 농약 리스트와 비료 리스트의 존재 여부 */
export const productTypeAtom = atom<number[]>([]);

/** 사용자 피드백 시트용 카테고리 */
export const categoryAtom = atom<string[]>([]);

/** 팜모닝 회원 주소를 기반으로한 좌표 */
export const defaultMapCenter = { lat: 37.5666805, lng: 126.9784147 };
export const defaultMapCenterV310 = { lat: 0, lng: 0 };
export const mapCenterAtom = atom<Location>(
  checkVersion("3.1.0", QA_FLAG_STAGING) ? defaultMapCenterV310 : defaultMapCenter,
);
export const mapZoomLevelAtom = atom<number>(10);
export const moveMapCenterAtom = atom<Location | null>(null);
export const googleMapAtom = atom<google.maps.Map | null>(null);
/** 바텀시트 데이터, 높이, 페이지 */
export const bottomSheetDataAtom = atom<DiseaseAnalysis[]>([]);
export const bottomSheetSnapAtom = atom<number | null>(null);
export const bottomSheetPage = atom<number>(1);
/** 주소 (팜모닝 및 ERP) */
export const mapAddressAtom = atom<MapAddress | null>(null);
/** 주소 선택 (3.1.0 에서 사용) */
export const selectedAddressAtom = atom<SelectedAddress>({
  address1: "",
  address2: "",
  address3: "",
  longitude: 0,
  latitude: 0,
});
/** 전체 결과 리스트 데이터 */
export const allResultListAtom = atom<DiseaseAnalysis[]>([]);
export const allResultListCropFilterAtom = atom<string[]>([]);
export const allResultListCropFilterLabelAtom = atom<string[]>([]);
export const sortOrderAtom = atom<"LATEST" | "DISTANCE">("LATEST");
/** 내 진단 결과 리스트 데이터 */
export const myResultListAtom = atom<DiseaseAnalysis[]>([]);
export const myResultListCropFilterAtom = atom<string[]>([]);
export const myResultListCropFilterLabelAtom = atom<string[]>([]);
/** 진단 결과 유입 루트 추적용 (gtm) */
export const resultPageSourceAtom = atom<string>("fromEtc");
export const monthlyDetailSourceAtom = atom<string>("fromEtc");
/** 내 주변 결과 리스트 데이터 */
export const aroundResultListAtom = atom<DiseaseAnalysis[]>([]);
export const aroundResultListCropFilterAtom = atom<string[]>([]);
export const aroundResultListCropFilterLabelAtom = atom<string[]>([]);
/** 월별 주의해야할 병해충 리스트 데이터 */
export const monthWarningListAtom = atom<DiseaseAnalysisMonthly[]>([]);
export const monthWarningListCropFilterAtom = atom<string[]>([]);
export const monthWarningListCropFilterLabelAtom = atom<string[]>([]);
/** 시/도 단위 좌표 */
export const district1CoordinateAtom = atom<Location>(defaultMapCenterV310);
/** 메인화면 내 진단/주변 진단 탭 */
export const aroundMyDiseaseActiveTabAtom = atom<"around" | "my">("around");
/** 페이지 스크롤 락 관련 atom */
// export const scrollLockAtom = atom<boolean>(false);
export const scrollObject = { scrollLock: false };

/** 리스트 페이징 처리 */
export const allResultListPage = atom<number>(1);
export const myResultListPage = atom<number>(1);
export const aroundResultListPage = atom<number>(1);
export const monthWarningListPage = atom<number>(1);

// default 주소
export const defaultAddress = {
  address1: "서울특별시",
  address2: "송파구",
  address3: "문정동",
  latitude: 37.4860499,
  longitude: 127.1168373,
};
