import React, { useState, useEffect, useCallback } from "react";
import { useRouter } from "next/router";
import { useInView } from "react-intersection-observer";
import Header from "./header";
import { useAtom } from "jotai";
import { COMMON } from "@constants/common";
import { ROUTE } from "@constants/route";
import { INDICATORS, logging } from "service/vhows";
import { ProgressBar } from "ui";
import {
  checkVersion,
  getLocalStorage,
  getSessionStorage,
  QA_FLAG_STAGING,
  setSessionStorage,
  reactNativePostMessage,
} from "@vhows/util";
import { headerText, headerText2, loadingAtom, loadingOnAtom, scrollTopAtom } from "store/global";
import * as Sentry from "@sentry/nextjs";

type Layout = {
  children?: React.ReactNode;
};

/**
 * 우성 모바일 네이티브 앱에서 웹뷰 뒤로가기에 대한 처리를 위해 JS 인터페이스(goBack)를 만들었음
 * 만약 인터페이스가 있다면 네이티브에서 뒤로가기 제어를 위해 JS 인터페이스를 호출 함
 */
const goBackAndroid = () => {
  try {
    // @ts-ignore
    Android.goBack();
    return true;
  } catch (e) {}
  return false;
};

const DEFAULT_TIMEOUT = 10;

const Layout = ({ children }: Layout) => {
  const [loading, setLoading] = useAtom(loadingAtom);
  const [loadingOn] = useAtom(loadingOnAtom);
  const [title, setTitle] = useAtom(headerText);
  const [title2, setTitle2] = useAtom(headerText2);
  const [isScrollTop] = useAtom(scrollTopAtom);
  const { ref: bodyRef, inView: bodyInView } = useInView({});
  const router = useRouter();

  useEffect(() => {
    const prevPath = getSessionStorage("currentPath");
    setSessionStorage("prevPath", prevPath ? prevPath : "/");
    setSessionStorage("currentPath", router.pathname);
  }, [router.asPath]);

  useEffect(() => {
    const userKey = getLocalStorage(COMMON.USER_ID) || getLocalStorage(COMMON.TEMPORARY_ID);
    userKey && Sentry.setUser({ id: userKey });
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  // const setPreviousPath = () => {
  //   if (sessionStorage) {
  //     const prevPath = sessionStorage.getItem("currentPath");
  //     sessionStorage.setItem("prevPath", prevPath ? prevPath : "/");
  //     sessionStorage.setItem("currentPath", router.pathname);
  //   }
  // };

  const makeHeaderTitle = useCallback(async () => {
    let titleText = "";
    switch (router.pathname) {
      case ROUTE.MAIN:
        titleText = "병해충 처방";
        break;
      case ROUTE.RESULT:
      case ROUTE.ERROR:
        titleText = "진단 결과";
        break;
      case ROUTE.RESULT_MY_LIST:
        titleText = "내 진단내역";
        break;
      case ROUTE.RESULT_ALL_LIST:
        titleText = checkVersion("3.1.0", QA_FLAG_STAGING) ? "실시간 전국 병해충 소식" : "모든 병해충 진단 결과";
        break;
      case ROUTE.CROPMAP:
        titleText = "병해충 지도";
        break;
      case ROUTE.RESULT_AROUND_LIST:
        titleText = "내 주변 병해충 진단";
        break;
      case ROUTE.RESULT_MONTH_WARNING:
        titleText = `이번 달 주의해야할 병해충`;
        break;
      default:
        if (router.pathname === "/result/[traceId]/detail/[detailId]" && !checkVersion("3.2.0", QA_FLAG_STAGING)) {
          titleText = "진단 결과";
        } else if (router.pathname === "/result/[traceId]") {
          titleText = "진단 결과";
        }
        break;
    }
    if (titleText) {
      setTitle(titleText);
      setTitle2("");
    }
  }, [router.pathname]);

  useEffect(() => {
    makeHeaderTitle();

    const start = (url: string, { shallow }: { shallow: boolean }) => {
      if (loadingOn && !shallow) setLoading(true);
    };
    const end = (url: string) => {
      setLoading(false);
    };

    // https://green-labs.atlassian.net/browse/MATA-473
    // 로딩 default timeout
    setTimeout(() => {
      if (loading) {
        setLoading(false);
      }
    }, DEFAULT_TIMEOUT * 1000);

    router.events.on("routeChangeStart", start);
    router.events.on("routeChangeComplete", end);
    router.events.on("routeChangeError", end);

    return () => {
      router.events.off("routeChangeStart", start);
      router.events.off("routeChangeComplete", end);
      router.events.off("routeChangeError", end);
    };
  }, [makeHeaderTitle, router, setLoading]);

  const callBacks = async (e: any, type: string) => {
    if (type === "header") {
      try {
        await analysis();
      } catch (e: any) {
        console.log(e);
      } finally {
        if (!goBackAndroid()) {
          if (isGoBackSupport()) {
            reactNativePostMessage("GO_BACK");
          } else {
            router.back();
          }
        }
      }
    } else if (type === "loading") {
      setLoading(e);
    } else if (type === "share") {
      console.log("공유하기 버튼 클릭");
    }
  };

  function isGoBackSupport() {
    return navigator.userAgent.includes("FarmmorningApp") && navigator.userAgent.includes("multiwebview");
  }

  const analysis = async () => {
    const userKey = getLocalStorage(COMMON.USER_ID) || getLocalStorage(COMMON.TEMPORARY_ID);
    if (userKey) await logging(null, INDICATORS.PREV_BTN_CLICK, null, userKey);
  };

  const scrollTop = () => {
    document.querySelector("html")!.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div className="relative m-auto max-w-xl flex-col">
        {/*-----header-----*/}
        <Header title={title} title2={title2} bodyScroll={bodyInView} callBacks={callBacks} />

        {/*-----body-----*/}
        <div className="relative pt-14">
          {checkVersion("1.0.2", QA_FLAG_STAGING) ? (
            <div ref={bodyRef} className={`block w-full bg-[#fff]`}></div>
          ) : (
            <div
              ref={bodyRef}
              className={`block w-full bg-[#F9F9F9] ${router.pathname === ROUTE.MAIN ? "h-3" : ""}`}
            ></div>
          )}
          {loading && <ProgressBar />}
          {children}
        </div>

        {isScrollTop && (
          <button className="highlight fixed bottom-[1.75rem] right-[1.75rem] cursor-pointer z-40" onClick={scrollTop}>
            <img width="48px" src="/icons/scroll-top.svg" alt="화면 최상단 위로" />
          </button>
        )}
        {/*-----footer-----*/}
      </div>
    </>
  );
};

export default Layout;
