import Link, { LinkProps } from "next/link";

type Props = Pick<LinkProps, "href"> &
  React.PropsWithChildren & { id?: string; dataGtm?: string; callbacks?: Function };

export function BottomFloatingLink({ id, dataGtm, href, children, callbacks }: Props) {
  return (
    <div className="fixed bottom-0 w-full max-w-xl rounded-xl z-30">
      <div className="h-5 w-full bg-gradient-to-t from-white" />
      <div className="bg-white px-5 text-center h-[76px]">
        {!!callbacks ? (
          <a
            id={id}
            data-gtm={dataGtm}
            onClick={() => {
              callbacks && callbacks(href);
            }}
          >
            <div className="mb-5 h-14 w-full rounded-xl bg-[#12B564] p-4 text-white">
              <div className="flex items-center justify-center text-lg font-semibold">{children}</div>
            </div>
          </a>
        ) : (
          <Link href={href}>
            <a id={id} data-gtm={dataGtm}>
              <div className="mb-5 h-14 w-full rounded-xl bg-[#12B564] p-4 text-white">
                <div className="flex items-center justify-center text-lg font-semibold">{children}</div>
              </div>
            </a>
          </Link>
        )}
      </div>
    </div>
  );
}
