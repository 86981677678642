import { useEffect, useRef, useState } from "react";

export type EachTab = {
  /**
   * 탭 링크 (a태그)
   */
  href?: string;
  /**
   * 탭 텍스트
   */
  text: string;
  /**
   * 탭 텍스트 styles
   */
  textStyles?: string;
  /**
   * 탭 cypress 테스트용 id
   */
  id?: string;
  /**
   * 탭 gtm
   */
  dataGtm?: string;
};

type Tab = {
  /**
   * 탭 구성 배열
   */
  tabArr: EachTab[];
  /**
   * 탭 개수가 6개 초과할 때 li태그 구성을 커스텀으로 만들어서 더해줘야 함.
   */
  customTab?: any;
  /**
   * 상단 라인 그리기
   */
  topBorder?: boolean;
  /**
   * href값이 있으면 탭 클릭시 스크롤로 해당 탭 아이디를 찾아서 이동 후 탑 마진을 주기 위한 값
   * default: 120(레이아웃 헤더 + 탭 높이 + 마진)
   */
  tabMargin?: number;
  /**
   * 탭 활성화시 기본으로 선택할 탭 (기본값: 0)
   */
  defaultTab?: number;
  /**
   * 외부에서 선택된 탭을 제어 할 때
   */
  currentTab?: number;
  /**
   * 탭 클릭에 대한 콜백
   */
  callbacks?: Function;
  /**
   * 탭 root container styles (기본값: fixed)
   */
  rootStyles?: string;
  /**
   * 탭 container css
   */
  containerStyle?: string;
  /**
   * 탭 css
   */
  tabStylesFocus?: string;
  /**
   * 탭 css
   */
  tabStylesUnfocus?: string;
  /**
   * 탭 텍스트 css
   */
  tabTextStylesFocus?: string;
  /**
   * 탭 텍스트 css
   */
  tabTextStylesUnfocus?: string;
};

export const Tab = ({
  tabArr,
  customTab,
  topBorder = false,
  tabMargin = 120,
  defaultTab = 0,
  currentTab = 0,
  callbacks,
  rootStyles = "fixed",
  containerStyle = "",
  tabStylesFocus = "",
  tabStylesUnfocus = "",
  tabTextStylesFocus = "",
  tabTextStylesUnfocus = "",
}: Tab) => {
  const [clickedTab, setClickedTab] = useState(defaultTab);
  const observer = useRef<IntersectionObserver>();
  const intersectionRatio = useRef<number[]>([]);
  const indexHash = useRef<Map<string, number>>(new Map());
  const intersectionFocusBlock = useRef(false);

  useEffect(() => {
    setClickedTab(currentTab);
  }, [currentTab]);

  useEffect(() => {
    if (!observer.current) {
      observer.current = new IntersectionObserver(tabObserverCallback, {
        threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
      });
    }
    if (tabArr[currentTab]?.href) {
      tabArr.forEach(tab => {
        const href = tab.href;
        if (href) {
          const el = document.querySelector(href);
          if (el) {
            observer.current!.observe(el);
          }
        }
      });
    }
    return () => {
      observer.current!.disconnect();
    };
  }, tabArr);

  function tabObserverCallback(entries: IntersectionObserverEntry[]) {
    entries.forEach(entry => {
      const targetId = entry.target.id;
      if (!indexHash.current.has(targetId)) {
        const index = tabArr.findIndex(tab => tab.href?.split("#")[1] === targetId);
        indexHash.current.set(targetId, index);
      }
      const index = indexHash.current.get(targetId);
      if (index !== undefined) {
        intersectionRatio.current[index] = +entry.intersectionRatio.toFixed(1);
      }
    });
    !intersectionFocusBlock.current && tabIntersectionFocus();
  }

  function tabIntersectionFocus() {
    let maxIndex = -1,
      maxRatio = -1;
    intersectionRatio.current.forEach((ratio, index) => {
      if (ratio >= maxRatio) {
        maxIndex = index;
        maxRatio = ratio;
      }
    });
    setClickedTab(maxIndex);
  }

  const onTabClick = (index: number) => {
    setClickedTab(index);
    if (callbacks) {
      callbacks(index, "onTabClick");
    }
    const href = tabArr[index].href;
    href && moveScrollToTab(index);
  };

  const moveScrollToTab = (index: number) => {
    const href = tabArr[index].href;
    if (!href) return;
    const el = document.querySelector(href);
    if (el) {
      const y = el.getBoundingClientRect().y;
      intersectionFocusBlock.current = true;
      setTimeout(() => {
        const el = document.querySelector("html");
        el?.scrollTo({ left: 0, top: el.scrollTop - (tabMargin - y), behavior: "smooth" });
        setTimeout(() => {
          intersectionFocusBlock.current = false;
        }, 500);
      });
    }
  };

  return (
    <div className={`relative z-20 w-full bg-white text-center text-sm font-medium max-w-xl ${rootStyles}`}>
      <ul className={`flex flex-row overflow-x-auto h-full ${containerStyle}`}>
        {tabArr.length > 6 ? (
          customTab
        ) : (
          <>
            {tabArr.map((tab: EachTab, index: number) => {
              return (
                <li
                  key={index}
                  className={`flex items-center relative h-full min-w-[100px] flex-none ${
                    clickedTab === index ? tabStylesFocus : tabStylesUnfocus
                  }`}
                >
                  <div
                    id={tab.id}
                    className={`text-bold px-4 text-[17px] w-full cursor-pointer ${
                      clickedTab === index ? "font-bold" : "font-medium"
                    } ${clickedTab === index ? tabTextStylesFocus : tabTextStylesUnfocus}`}
                    onClick={() => {
                      onTabClick(index);
                    }}
                    {...(tab.dataGtm && { "data-gtm": tab.dataGtm })}
                  >
                    <span className={tab.textStyles}>{tab.text}</span>
                  </div>
                  {clickedTab === index && (
                    <div className="absolute bottom-0 w-full border-b-[3px] border-[#262626]"></div>
                  )}
                </li>
              );
            })}
          </>
        )}
      </ul>
      {topBorder && <div className="absolute top-0 w-full border-b-[1px] border-[#EDEFF2]"></div>}
      <div className="absolute bottom-0 w-full border-b-[1px] border-[#EDEFF2]"></div>
    </div>
  );
};
