export const Addresses = ({
  addresses,
  addressName,
  setItemClick,
  region,
  regionExistClassNames,
  regionNonExistClassNames,
  placeholder,
  buttonId = "",
  addressWrapperId = "",
}: any) => {
  const clickItem = (e: any, add: any) => {
    if (e) {
      setItemClick((prev: any) => ({
        ...prev,
        [addressName]: add[addressName],
        longitude: add.longitude,
        latitude: add.latitude,
      }));
    }
  };

  const clickAddress = (e: any, str: string) => {
    if (str === "address1") {
      setItemClick((prev: any) => ({
        ...prev,
        address1: "",
        address2: "",
        address3: "",
      }));
    } else if (str === "address2") {
      setItemClick((prev: any) => ({ ...prev, address2: "", address3: "" }));
    } else {
      setItemClick((prev: any) => ({ ...prev, address3: "" }));
    }
  };

  return (
    <>
      {/*상단 주소 선택 버튼*/}
      <button
        id={buttonId}
        className={
          region
            ? `w-full border-x border-[#CCCCCC] bg-[#F7F7F7] ${regionExistClassNames}`
            : `w-full rounded-[10px] border border-[#262626] p-[12px] shadow-[0_4px_8px_0_rgba(0,0,0,0.08)] ${regionNonExistClassNames}`
        }
      >
        {!region ? (
          <span className={"inline-block w-full text-left text-base text-[#999999]"}>{placeholder}</span>
        ) : (
          <>
            <div
              className="mx-3.5 mt-3.5 flex items-center border-b border-[#ECECEC] pb-3.5"
              onClick={e => clickAddress(e, addressName)}
            >
              <div className={"grow text-left text-base text-[#262626]"}>{region}</div>
              <div>
                <img className="mb-2" src="./icons/up.svg" alt="up icon" />
                <img className="" src="./icons/down.svg" alt="down icon" />
              </div>
            </div>
          </>
        )}
      </button>

      {/*선택 주소 목록*/}
      {!region && (
        <div>
          <ul id={addressWrapperId}>
            {addresses &&
              addresses.map((add: any, idx: number) => (
                <li
                  className={"flex cursor-pointer border-b border-[#f2f2f2] py-[16px]"}
                  key={idx}
                  onClick={e => clickItem(e, add)}
                >
                  <span className={"grow py-[4px] text-base font-bold leading-7"}>
                    {add.address3 ? add.address3 : add.address2 ? add.address2 : add.address1}
                  </span>
                  <span
                    className={
                      "h-9 w-[50px] cursor-pointer rounded-lg bg-[#F2F2F2] px-[12px] py-[6px] text-[15px] leading-[24px]"
                    }
                  >
                    선택
                  </span>
                </li>
              ))}
          </ul>
        </div>
      )}
    </>
  );
};
