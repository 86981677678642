import React from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

type InformationV2 = {
  /**
   * 안내 문구
   */
  text: string;
  /**
   * 배경 색상
   * (bg-[#ABCDEF] 또는 bg-blue-50 형태로 전달)
   */
  bgColor?: string;
  /**
   * 텍스트 및 아이콘 색상
   * (text-[#ABCDEF] 또는 text-blue-50 형태로 전달)
   */
  textColor?: string;
  /**
   * 테두리 색상
   * (border-[#ABCDEF] 또는 border-blue-50 형태로 전달)
   */
  borderColor?: string;
  /**
   * 커스텀 icon (컴포넌트 형태로 전달 (JSX.Element))
   */
  icon?: JSX.Element;
};

export const InformationV2 = ({
  text,
  bgColor = "bg-blue-50",
  textColor = "text-blue-500",
  borderColor = "",
  icon,
}: InformationV2) => {
  return (
    <>
      <div
        id="information"
        className={`flex flex-wrap rounded-xl p-4 ${bgColor} ${textColor} ${
          borderColor !== "" && "border " + borderColor
        }`}
      >
        {icon ? (
          <div className="flex basis-1/12 justify-center self-start pt-1">{icon}</div>
        ) : (
          <div className="flex basis-1/12 justify-center pt-0.5">
            <InformationCircleIcon className={`h-4 w-4 ${textColor}`} />
          </div>
        )}
        <div className="flex basis-10/12 flex-col">
          <div className={`flex-1 text-[15px] font-medium`}>{text}</div>
        </div>
        <div className="flex-1"></div>
      </div>
    </>
  );
};
