import Image from "next/image";
import { map, pipe, toArray } from "@fxts/core";
import { TitleBox } from "../title-box-v1";

type Card = {
  /**
   * 카드 타이틀
   */
  title: string;
  /**
   * 타이틀 아래쪽 서브 타이틀
   */
  subTitle: string;
  /**
   * 타이틀 영역 우측 아이콘 (현재는 url로만)
   */
  icon?: string;
  /**
   * 타이틀 아래쪽 표기할 이미지 배열
   */
  imgArr?: string[];
  /**
   * 하단 텍스트 영역
   */
  descArr?: any[];
  /**
   * 부가 옵션
   */
  option?: {
    /**
     * 이미지를 2개만 표기할지 (일반 용도), 전체를 횡스크롤로 표기할지 여부 (상세 보기 용도)
     */
    makeTwoImg?: boolean;
    /**
     * 상세 보기 버튼 노출 여부 (아래 detailBtnFunc 정의 필요)
     */
    detailBtnVisible?: boolean;
    /**
     * 상세 보기 함수
     */
    detailBtnFunc?: Function;
    /**
     * 이미지 클릭 함수 (상세보기 카드(makeTwoImg: false)일때 사용 가능)
     */
    imgClickFunc?: Function;
    /**
     * descArr 내의 desc 표현방식. 일반 Array 타입인지 (false), <br/> 태그를 사용하는지 (true)
     */
    useBrTag?: boolean;
    /**
     * 상세보기 카드(makeTwoImg: false)일때 subTitle 아래쪽에 노출되는 부가 설명
     */
    subDesc?: any;
  };
};

/**
 * TitleBox 컴포넌트 사용, 해당 컴포넌트 수정시 확인 필요.
 */
export const Card = ({
  title = "",
  subTitle = "",
  icon = "",
  imgArr = [],
  descArr = [{ descTitle: "", desc: [] }],
  option = {
    makeTwoImg: true,
    detailBtnVisible: false,
    detailBtnFunc: () => {},
    imgClickFunc: () => {},
    useBrTag: false,
    subDesc: <></>,
  },
}: Card) => {
  const makeDec = (decArray: string[] | string) => {
    let decArr = decArray;
    let index = 0;
    if (typeof decArray === "string") {
      if (option.useBrTag && decArray.length > 0) {
        decArr = decArray.split("<br/>");
      } else {
        decArr = decArray.split("\n");
      }
    }
    return pipe(
      decArr,
      map((v: string) => {
        return (
          <li key={index++} className="mt-1">
            <span className="text-base font-normal text-neutral-600">{v.replaceAll("-", "")}</span>
          </li>
        );
      }),
      toArray,
    );
  };

  const makeImageCard = () => {
    let index = 0;
    return pipe(
      imgArr,
      map(v => {
        return (
          <button
            key={index++}
            id="image"
            className={"mr-2 h-[172px] w-[260px] overflow-hidden rounded-xl"}
            onClick={(index => (e: any) => {
              option.imgClickFunc ? option.imgClickFunc(e, index) : () => {};
            })(index)}
          >
            <Image
              priority={true}
              className="!-top-1/3 h-[170px] w-[260px] rounded-xl object-cover"
              src={v}
              width={"100%"}
              height={"100%"}
              layout="responsive"
              alt={"propImg"}
            />
          </button>
        );
      }),
      toArray,
    );
  };

  return (
    <>
      {/*카드 영역*/}
      <div id="card" className={`${option.makeTwoImg ? "mb-8 rounded-3xl px-5 pt-7 pb-5" : ""} bg-white`}>
        <div className="">
          {/*상단 영역*/}
          <div className={`flex flex-wrap items-center ${option.makeTwoImg ? "" : "bg-[#F9F9F9] px-5 pt-6 pb-7"}`}>
            {/*좌측 상단 타이틀*/}
            <div id="cardTitle" className="flex basis-9/12 flex-col">
              <div className="text-sm text-green-500">{title}</div>
              <div className={`font-bold text-gray-700 ${option.makeTwoImg ? "text-lg " : "text-2xl"}`}>{subTitle}</div>
            </div>

            {/*우측 상단 아이콘*/}
            <div className="flex-1" />
            <div className="ml-2 mr-1 flex">{icon && <Image src={icon} width={45} height={45} alt="icon" />}</div>

            {!option.makeTwoImg && <div className={`text-sm font-normal text-zinc-500`}>{option.subDesc}</div>}

            {/*상단 이미지*/}
            {imgArr && imgArr.length > 0 ? (
              option.makeTwoImg ? (
                <div id="cardImage" className="mt-4 w-full">
                  <div className="grid grid-cols-2 gap-2">
                    <div key={title + subTitle} className={""}>
                      <Image
                        priority={true}
                        className="rounded-xl object-cover"
                        src={imgArr[0]}
                        width={"100%"}
                        height={"100%"}
                        layout="responsive"
                        alt={"propImg"}
                      />
                    </div>
                    {imgArr[1] && (
                      <div key={title + subTitle + "2"} className={""}>
                        <Image
                          priority={true}
                          className="rounded-xl object-cover"
                          src={imgArr[1]}
                          width={"100%"}
                          height={"100%"}
                          layout="responsive"
                          alt={"propImg"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div id="cardImage" className="mt-3 w-full overflow-x-scroll">
                  <div className="flex min-w-max flex-nowrap">{makeImageCard()}</div>
                </div>
              )
            ) : (
              <></>
            )}
          </div>

          {/*하단 영역*/}
          <div className={`${option.makeTwoImg ? "" : "mt-6 px-5 pb-[60px]"}`}>
            {/*하단 설명*/}
            {pipe(
              descArr,
              map(v => {
                return (
                  <div key={v.descTitle} className={"mt-6 w-full"}>
                    <TitleBox
                      text={v.descTitle}
                      icon={v.titleIcon ? v.titleIcon : undefined}
                      textColor={v.textColor ? v.textColor : undefined}
                      bgColor={v.bgColor ? v.bgColor : undefined}
                    />

                    <ul
                      className="ml-10 list-outside list-disc pr-5 pt-3"
                      style={{ listStyle: `${option.makeTwoImg ? "disc" : "circle"}` }}
                    >
                      {makeDec(v.desc)}
                    </ul>
                  </div>
                );
              }),
              toArray,
            )}

            {/*상세 보기 버튼*/}
            {option.detailBtnVisible && (
              <div className="mt-3 w-full text-right">
                <button
                  id="viewDetailButton"
                  className="relative inline-block h-9 w-28 rounded-3xl border border-green-500 p-2 text-center"
                  onClick={(e: any) => {
                    option.detailBtnFunc ? option.detailBtnFunc(e) : () => {};
                  }}
                >
                  <span className="absolute left-6 top-2.5 text-xs font-bold text-green-500">자세히 보기 〉</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
