import Image from "next/image";
import { useState } from "react";

type BlurringImage = {
  /**
   * 실제 이미지 경로
   */
  src: string;
  /**
   * NextImage 기본 속성
   */
  width: string | number;
  /**
   * NextImage 기본 속성
   */
  height: string | number;
  /**
   * NextImage 기본 속성
   */
  alt?: string;
  /**
   * 이미지를 감싸는 div영역 스타일 (이미지 있을때 없을때 전체 적용
   */
  customStyle?: string;
  /**
   * 이미지를 감싸는 div영역 스타일 (이미지 있을때만 적용
   */
  customStyleDiv?: string;
  /**
   * Image태그 클래스
   */
  className?: string;
  /**
   * NextImage 기본 속성
   */
  layout?: "fixed" | "fill" | "intrinsic" | "responsive" | undefined;
  /**
   * NextImage 기본 속성
   * true로 지정할경우 높은 우선순위
   */
  priority?: boolean;
  /**
   * 이미지 썸네일을 커스텀 컴포넌트 전달 할 때 사용
   */
  customThumbnail?: JSX.Element;
  /**
   * NextImage 기본 속성
   * 이미지 사이즈
   */
  sizes?: string;
  /**
   * 이미지 사이즈와 containerDiv 크기를 같도록 처리
   */
  useImageFit?: boolean;
  /**
   * NextImage 기본 속성
   * 이미지 최적화 여부
   */
  unoptimized?: boolean;
};

export function BlurringImage({
  src = "",
  width = "",
  height = "",
  alt,
  customStyle,
  customStyleDiv,
  customThumbnail,
  useImageFit,
  ...props
}: BlurringImage) {
  const [hasPlaceholder, setHasPlaceholder] = useState(true);

  return (
    <div className={`relative h-full w-full overflow-hidden ${customStyle ? customStyle : ""}`}>
      {(hasPlaceholder || !src) && (
        <>
          {!!customThumbnail ? (
            customThumbnail
          ) : (
            <div className="h-full w-full rounded-xl bg-[#F7F8FA] text-center">
              <div className="relative p-[30%]">
                <Image
                  className="absolute"
                  priority={true}
                  src="/icons/default-thumbnail.svg"
                  layout="responsive"
                  loading="eager"
                  width="100%"
                  height="100%"
                  alt="defaultImg"
                />
              </div>
            </div>
          )}
        </>
      )}
      {!!src && (
        <div
          className={`${hasPlaceholder ? "hidden" : ""} ${customStyleDiv ? customStyleDiv : ""}`}
          style={useImageFit ? { height: height, width: width } : {}}
        >
          <Image
            src={src}
            priority={true}
            height={hasPlaceholder ? "0%" : height}
            width={hasPlaceholder ? "0%" : width}
            alt={!!alt ? alt : "Image"}
            {...props}
            onLoadingComplete={() => setHasPlaceholder(false)}
          />
        </div>
      )}
    </div>
  );
}
