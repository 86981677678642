import { useRouter } from "next/router";
import Image from "next/image";
import { useAtom } from "jotai";
import { ROUTE } from "@constants/route";
import { detailIndexAtom, plantixAtom, selectedPesticedeAtom } from "store/global";
import { getSessionStorage } from "@vhows/util";
import Header from "./index";

const HeaderV2 = ({ title, callBacks }: Header) => {
  const [responsePlantix] = useAtom(plantixAtom);
  const [detailIndex] = useAtom(detailIndexAtom);
  const [selectedPesticide] = useAtom(selectedPesticedeAtom);
  const router = useRouter();

  const nextRouterCheck = () => {
    if (router.pathname.indexOf(ROUTE.DETAIL) > -1) {
      if (router.pathname.indexOf("compare") < 0) {
        return ROUTE.RESULT;
      } else {
        return responsePlantix && "predictedDiagnoses" in responsePlantix
          ? `/result/${responsePlantix.traceId}/detail/${responsePlantix.predictedDiagnoses[detailIndex].id}/`
          : ROUTE.DETAIL;
      }
    } else if (router.pathname.indexOf(ROUTE.PESTICIDES) > -1) {
      if (router.pathname === ROUTE.PESTICIDES) {
        return responsePlantix &&
          "predictedDiagnoses" in responsePlantix &&
          getSessionStorage("currentPath").indexOf("detail") > -1
          ? `/result/${responsePlantix.traceId}/detail/${responsePlantix.predictedDiagnoses[detailIndex].id}/`
          : ROUTE.RESULT;
      } else {
        if (!!selectedPesticide) title = selectedPesticide.productName;
        return ROUTE.PESTICIDES;
      }
    }
    return ROUTE.MAIN;
  };

  return (
    <>
      <div id="header" className="fixed z-30 m-auto flex h-14 w-full max-w-xl bg-white text-[#1F2024]">
        <a
          className="h-14 w-20 cursor-pointer pt-3 text-center"
          id="backButton"
          onClick={(e: any) => {
            callBacks(e, "header");
          }}
        >
          <Image src="/icons/left-contents.svg" width="32px" height="32px" alt="backButton"></Image>
        </a>
        <div className="w-full self-center mr-14 text-center text-[19px] font-bold">{title}</div>
      </div>
    </>
  );
};

export default HeaderV2;
