import { useEffect, useState, useCallback } from "react";
import Image from "next/image";
import Link from "next/link";
import { map, pipe, toArray } from "@fxts/core";
import { TitleBoxV2 } from "../title-box-v2";
import { Tab } from "../tab-v1";
import { BlurringImage } from "../blurring-image-v1";
import { useMoveScroll } from "@vhows/util";

type CardV2 = {
  /**
   * 카드 타이틀
   */
  title: string;
  /**
   * 타이틀 아래쪽 서브 타이틀
   */
  subTitle: string;
  /**
   * 타이틀 영역 우측 아이콘 (현재는 url로만)
   */
  icon?: string;
  /**
   * 타이틀 아래쪽 표기할 이미지 배열
   */
  imgArr?: string[];
  /**
   * 하단 텍스트 영역
   */
  descArr?: any[];
  /**
   * 부가 옵션
   */
  option?: {
    /**
     * 이미지를 2개만 표기할지 (일반 용도), 전체를 횡스크롤로 표기할지 여부 (상세 보기 용도)
     */
    makeTwoImg?: boolean;
    /**
     * 상세 보기 버튼 노출 여부 (아래 detailBtnFunc 정의 필요)
     */
    detailBtnVisible?: boolean;
    /**
     * 상세 보기 페이지 경로
     */
    detailBtnSrc?: string;
    /**
     * 상세 보기 함수
     */
    detailBtnFunc?: Function;
    /**
     * 이미지 클릭시 이동할 페이지 경로 (상세보기 카드(makeTwoImg: false)일때 사용 가능)
     */
    imgClickSrc?: string;
    /**
     * 이미지 클릭 함수 (상세보기 카드(makeTwoImg: false)일때 사용 가능)
     */
    imgClickFunc?: Function;
    /**
     * descArr 내의 desc 표현방식. 일반 Array 타입인지 (false), <br/> 태그를 사용하는지 (true)
     */
    useBrTag?: boolean;
    /**
     * 상세보기 카드(makeTwoImg: false)일때 subTitle 아래쪽에 노출되는 부가 설명
     */
    subDesc?: any;
    /**
     * 추가 커스텀 컴포넌트 영역
     */
    otherComponent?: any;
    /**
     * 탭 클릭 시 스크롤 오차 범위
     */
    scrollPoint?: number;
  };
};

/**
 * TitleBoxV2 컴포넌트 사용, 해당 컴포넌트 수정시 확인 필요.
 */
export const CardV2 = ({
  title = "",
  subTitle = "",
  icon = "",
  imgArr = [],
  descArr = [{ descTitle: "", desc: [] }],
  option = {
    makeTwoImg: true,
    detailBtnVisible: false,
    detailBtnSrc: "",
    detailBtnFunc: () => {},
    imgClickSrc: "",
    imgClickFunc: () => {},
    useBrTag: false,
    subDesc: <></>,
    otherComponent: <></>,
    scrollPoint: 0,
  },
}: CardV2) => {
  const makeDec = (decArray: string[] | string) => {
    let decArr = decArray;
    let index = 0;
    if (typeof decArray === "string") {
      if (option.useBrTag && decArray.length > 0) {
        decArr = decArray.split("<br/>");
      } else {
        decArr = decArray.split("\n");
      }
    }
    return pipe(
      decArr,
      map((v: string) => {
        return (
          <li key={index++} className="mt-1 marker:text-[13px]">
            <span className="text-base font-normal text-[#1F2024]">{v.replaceAll("-", "")}</span>
          </li>
        );
      }),
      toArray,
    );
  };

  const makeImageCard = () => {
    let index = 0;
    return pipe(
      imgArr,
      map(v => {
        return (
          <div key={index++} className={"mr-2 h-[244px] w-[300px] overflow-hidden rounded-2xl first:ml-4 last:mr-4"}>
            <Link href={option.imgClickSrc as string}>
              <a
                id="image"
                onClick={(index => (e: any) => {
                  option.imgClickFunc ? option.imgClickFunc(e, index) : () => {};
                })(index)}
              >
                <div className="relative">
                  <div className="absolute top-[174px] z-10 h-[70px] w-full rounded-b-2xl bg-gradient-to-t from-black/75 to-transparent" />
                  <div className="absolute top-[204px] right-4 z-20 h-6">
                    <Image src={"/icons/touch.svg"} width="24px" height="24px" alt="touchIcon" />
                  </div>
                  <BlurringImage
                    priority={true}
                    className="!-top-1/3 h-[170px] w-[260px] rounded-2xl object-cover"
                    src={v}
                    width="100%"
                    height="100%"
                    layout="responsive"
                    sizes="500px"
                    alt="propImg"
                    customThumbnail={
                      <div className="h-[274px] w-[300px] rounded-xl bg-[#F7F8FA] text-center">
                        <div className="relative top-[79px] px-[37%]">
                          <Image
                            className="absolute"
                            priority={true}
                            src="/icons/default-thumbnail.svg"
                            layout="responsive"
                            loading="eager"
                            sizes="500px"
                            width="100%"
                            height="100%"
                            alt="defaultImg"
                          />
                        </div>
                      </div>
                    }
                  />
                </div>
              </a>
            </Link>
          </div>
        );
      }),
      toArray,
    );
  };

  const makeTabArr = (descArr: any) => {
    const TabArr: any = [];
    descArr.map((v: any, index: number) => {
      TabArr.push({ text: v.descTitle, href: `#tab${index}` });
    });
    return TabArr;
  };

  return (
    <>
      {/*카드 영역*/}
      <div className={`${option.makeTwoImg ? "mt-4 rounded-3xl px-4 py-8" : ""} bg-white`}>
        <div id="card">
          {/*상단 영역*/}
          <div className={`flex flex-wrap items-center`}>
            <div className={`${option.makeTwoImg ? "" : "px-4 pt-5"}`}>
              {/*좌측 상단 타이틀*/}
              <div id="cardTitle" className="flex basis-9/12 flex-row">
                <div
                  className={`font-bold ${
                    option.makeTwoImg ? "text-[22px] text-[#1F2024]" : "text-2xl text-[#262626]"
                  }`}
                >
                  {title}
                </div>
                <div className="ml-1 self-center rounded bg-[#F7F8FA] px-[7px] pb-[5px] pt-[3px] text-[13px] font-medium text-[#8B8D94]">
                  {subTitle}
                </div>
              </div>

              {/*우측 상단 아이콘*/}
              <div className="flex-1" />
              <div className="ml-2 mr-1 flex">{icon && <Image src={icon} width={45} height={45} alt="icon" />}</div>

              {!option.makeTwoImg && (
                <div className={`mt-2.5 text-[13px] font-normal text-[#8B8D94]`}>{option.subDesc}</div>
              )}
            </div>

            {/*상단 이미지*/}
            {imgArr && imgArr.length > 0 ? (
              option.makeTwoImg ? (
                <div id="cardImage" className="mt-4 w-full">
                  <div className="grid grid-cols-2 gap-2">
                    <div key={title + subTitle}>
                      <BlurringImage
                        className="rounded-xl object-cover"
                        src={imgArr[0]}
                        width="100%"
                        height="100%"
                        layout="responsive"
                        sizes="(min-width: 576px) 250px, 50vw"
                        alt="propImg"
                      />
                    </div>
                    {imgArr[1] && (
                      <div key={title + subTitle + "2"}>
                        <BlurringImage
                          className="rounded-xl object-cover"
                          src={imgArr[1]}
                          width="100%"
                          height="100%"
                          layout="responsive"
                          sizes="(min-width: 576px) 250px, 50vw"
                          alt="propImg"
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div id="cardImage" className="mt-4 w-full overflow-x-scroll pb-6">
                  <div className="flex min-w-max flex-nowrap">{makeImageCard()}</div>
                </div>
              )
            ) : (
              <></>
            )}
          </div>

          {/*하단 영역*/}
          <div className={`${option.makeTwoImg ? "" : "px-4 pb-[60px]"}`}>
            {!option.makeTwoImg && descArr.length > 1 && (
              <Tab tabArr={makeTabArr(descArr)} rootStyles={`h-[47px] sticky top-[55px]`}></Tab>
            )}

            {/*하단 설명*/}
            {descArr.map((v, index: number) => {
              return (
                <div key={v.descTitle} id={`tab${[index]}`} className="mt-6 w-full">
                  <TitleBoxV2
                    text={v.descTitle}
                    textColor={v.textColor ? v.textColor : undefined}
                    bgColor={v.bgColor ? v.bgColor : undefined}
                    fitStyle={true}
                  />

                  <ul className="ml-8 list-outside list-disc pr-4 pt-1" style={{ listStyle: "disc" }}>
                    {makeDec(v.desc)}
                  </ul>
                </div>
              );
            })}

            {/*상세 보기 버튼*/}
            {option.detailBtnVisible && (
              <div className="mt-4 w-full text-center">
                <div className="w-full rounded-[30px] border border-[#12B564] bg-white p-3 text-center drop-shadow">
                  <Link href={option.detailBtnSrc as string}>
                    <a
                      id="viewDetailButton"
                      onClick={(e: any) => {
                        option.detailBtnFunc ? option.detailBtnFunc(e) : () => {};
                      }}
                    >
                      <p className="text-[15px] font-bold text-[#12B564]">자세히 보기</p>
                    </a>
                  </Link>
                </div>
              </div>
            )}

            {/*추가 컴포넌트 영역*/}
            {option.otherComponent && <div className="mt-6">{option.otherComponent}</div>}
          </div>
        </div>
      </div>
    </>
  );
};
