import { useEffect, useRef, useState } from "react";
import Image from "next/image";
import { throttle } from "lodash-es";
import { EachTab } from "../tab-v1";

type TabV2 = {
  /**
   * 탭 구성 배열
   */
  tabArr: EachTab[];
  /**
   * 탭 확장 여부
   */
  expanded: any;
  /**
   * 확장,축소 관련 gtm 이벤트
   */
  expandGtm?: string;
  contractGtm?: string;
  /**
   * 상단 라인 그리기
   */
  topBorder?: boolean;
  /**
   * href값이 있으면 탭 클릭시 스크롤로 해당 탭 아이디를 찾아서 이동 후 탑 마진을 주기 위한 값
   * default: 120(레이아웃 헤더 + 탭 높이 + 마진)
   */
  tabMargin?: number;
  /**
   * 탭 활성화시 기본으로 선택할 탭 (기본값: 0)
   */
  defaultTab?: number;
  /**
   * 외부에서 선택된 탭을 제어 할 때
   */
  currentTab?: number;
  /**
   * 탭 클릭에 대한 콜백
   */
  callbacks?: Function;
  /**
   * 탭 root container styles (기본값: fixed)
   */
  rootStyles?: string;
  /**
   * 탭 container css
   */
  containerStyle?: string;
  /**
   * 탭 css
   */
  tabStylesFocus?: string;
  /**
   * 탭 css
   */
  tabStylesUnfocus?: string;
  /**
   * 탭 텍스트 css
   */
  tabTextStylesFocus?: string;
  /**
   * 탭 텍스트 css
   */
  tabTextStylesUnfocus?: string;

  isVisibleExpandIcon?: boolean;
  bottomBorder?: boolean;
};

export const TabV2 = ({
  tabArr,
  expanded,
  topBorder = false,
  tabMargin = 120,
  defaultTab = 0,
  currentTab = 0,
  callbacks,
  rootStyles = "fixed",
  containerStyle = "",
  tabStylesFocus = "",
  tabStylesUnfocus = "",
  tabTextStylesFocus = "",
  tabTextStylesUnfocus = "",
  expandGtm,
  contractGtm,
  isVisibleExpandIcon = true,
  bottomBorder = true,
}: TabV2) => {
  const [clickedTab, setClickedTab] = useState(defaultTab);
  const [scrollYMax, setScrollYMax] = useState<boolean>(false);
  const [tabCoordinateTop, setTabCoordinateTop] = useState<number[]>([]);
  const [tabCoordinateBottom, setTabCoordinateBottom] = useState<number[]>([]);
  const observer = useRef<IntersectionObserver>();
  const intersectionRatio = useRef<number[]>([]);
  const indexHash = useRef<Map<string, number>>(new Map());
  const intersectionFocusBlock = useRef(false);

  useEffect(() => {
    // const tabCoorTop: number[] = [];
    // const tabCoorBottom: number[] = [];
    // tabArr.map((v, index) => {
    //   const top = document.getElementById(v.href?.split("#")[1] as string)?.offsetTop || -1;
    //   tabCoorTop.push(top);
    //   const height = document.getElementById(v.href?.split("#")[1] as string)?.offsetHeight || -1;
    //   const bottom = top + height || -1;
    //   tabCoorBottom.push(bottom);
    // });
    // if (tabCoorTop) setTabCoordinateTop(tabCoorTop);
    // if (tabCoorBottom) setTabCoordinateBottom(tabCoorBottom);

    let scrollY = 0;
    window.addEventListener(
      "scroll",
      throttle(() => {
        const lastHeight = document.getElementById("detailPage")?.offsetHeight;
        scrollY = window.scrollY;
        if (lastHeight && lastHeight - window.innerHeight <= scrollY) {
          setScrollYMax(true);
        }
      }, 500),
    );
  }, []);

  useEffect(() => {
    setClickedTab(currentTab);
  }, [currentTab]);

  useEffect(() => {
    if (!observer.current) {
      observer.current = new IntersectionObserver(tabObserverCallback, {
        threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
      });
    }
    if (tabArr[currentTab]?.href) {
      tabArr.forEach(tab => {
        const href = tab.href;
        if (href) {
          const el = document.querySelector(href);
          if (el) {
            observer.current!.observe(el);
          }
        }
      });
    }
    return () => {
      observer.current!.disconnect();
    };
  }, [tabArr]);

  useEffect(() => {
    const element = document.getElementById(tabArr[clickedTab]?.id as string);
    if (element) element.scrollIntoView({ behavior: "auto", block: "nearest", inline: "center" });
  }, [clickedTab]);

  function tabObserverCallback(entries: IntersectionObserverEntry[]) {
    entries.forEach(entry => {
      const targetId = entry.target.id;
      if (!indexHash.current.has(targetId)) {
        const index = tabArr.findIndex(tab => tab.href?.split("#")[1] === targetId);
        indexHash.current.set(targetId, index);
      }
      const index = indexHash.current.get(targetId);
      if (index !== undefined) {
        intersectionRatio.current[index] = +entry.intersectionRatio.toFixed(1);
      }
    });
    !intersectionFocusBlock.current && tabIntersectionFocus();
  }

  function tabIntersectionFocus() {
    let maxIndex = -1,
      maxRatio = -1;
    intersectionRatio.current.forEach((ratio, index) => {
      if (scrollYMax ? ratio >= maxRatio : ratio > maxRatio) {
        maxIndex = index;
        maxRatio = ratio;
      }
    });
    setClickedTab(maxIndex);
  }

  const onTabClick = (index: number) => {
    setClickedTab(index);
    if (callbacks) {
      callbacks(index, "onTabClick");
    }
    const href = tabArr[index].href;
    href && moveScrollToTab(index);
  };

  const moveScrollToTab = (index: number) => {
    const href = tabArr[index].href;
    if (!href) return;
    const el = document.querySelector(href);
    if (el) {
      const y = el.getBoundingClientRect().y;
      intersectionFocusBlock.current = true;
      setTimeout(() => {
        const el = document.querySelector("html");
        el?.scrollTo({ left: 0, top: el.scrollTop - (tabMargin - y), behavior: "smooth" });
        setTimeout(() => {
          intersectionFocusBlock.current = false;
        }, 1000);
      });
    }
  };

  return (
    <div
      className={`z-40 w-full bg-white flex flex-row text-center text-sm font-medium max-w-xl ${
        expanded ? "" : "h-[73px]"
      } ${rootStyles}`}
    >
      <ul
        className={`flex h-full ${
          expanded ? "flex-wrap mx-2 mt-[6px] mb-4" : "flex-row overflow-x-auto"
        } ${containerStyle}`}
      >
        {tabArr.map((tab: EachTab, index: number) => {
          return (
            <li
              key={index}
              className={`flex items-center relative h-full flex-none ${
                clickedTab === index ? tabStylesFocus : tabStylesUnfocus
              } ${expanded ? "mt-2.5 ml-2" : "first:ml-4 last:mr-14 mr-2"}`}
            >
              <div
                id={tab.id}
                className={`text-bold flex items-center rounded-[20px] px-4 text-[17px] h-10 border w-full cursor-pointer ${
                  clickedTab === index ? tabTextStylesFocus : tabTextStylesUnfocus
                }`}
                data-gtm={tab.dataGtm}
                onClick={() => {
                  onTabClick(index);
                }}
                {...(tab.dataGtm && { "data-gtm": tab.dataGtm })}
              >
                <span className={tab.textStyles}>{tab.text}</span>
              </div>
            </li>
          );
        })}
      </ul>
      {isVisibleExpandIcon && tabArr.length > 3 && (
        <div
          className="w-[60px] h-[76px] absolute right-0"
          data-gtm={expanded ? expandGtm : contractGtm}
          onClick={() => {
            if (callbacks) callbacks(expanded, "expanded");
          }}
        >
          <div className={`absolute h-[76px] w-[30px] bg-gradient-to-l from-white ${expanded && "hidden"}`} />
          <div className="absolute right-0 h-[76px] w-[30px] bg-white" />
          <div className="absolute left-[24px] top-[26px]">
            <Image
              src={expanded ? "/icons/chevron-up-line.svg" : "/icons/chevron-down-line.svg"}
              width={20}
              height={20}
              alt="dropdownIcon"
            />
          </div>
        </div>
      )}
      {topBorder && <div className="absolute top-0 w-full border-b-[1px] border-[#EDEFF2]"></div>}
      {bottomBorder && <div className="absolute bottom-0 w-full border-b-[1px] border-[#EDEFF2]"></div>}
    </div>
  );
};
