import axios, { AxiosResponse } from "axios";
import { checkVersion, Location, QA_FLAG_STAGING, getLocalStorage, QA_FLAG_DEV } from "@vhows/util";
import { PAGE_LIMIT } from "facil-mediation/constants/common";
import { fertilizersDataListAtom } from "../store/global";
const qs = require("querystring");

export type Errors = {
  message: string;
  type: string;
  location: string[];
};

export type DiseaseAnalysisError = {
  code: string;
  message: string;
  errorData?: {
    errors: Errors[];
    traceId: string;
  };
};

export type DiseaseAnalysis = {
  id: number;
  predictedDiagnoses: Diagnoses[];
  traceId: string;
  cropHealth: string;
  crops: string[];
  district: MapAddress;
  imgUrl: string;
  imgFocus: string;
  imgDistance: string;
  satisfactionRating: SATISFACTION;
  apiStatus: string;
  errors?: Errors[];
  userKey: string;
  createDate: Date;
};

export type Diagnoses = {
  id: number;
  peatId: string;
  crops: string[];
  cropsKorName: string[]; // v3.2.0 이전에 사용
  cropsName: string[]; // v3.2.0부터 사용
  commonName: string;
  diseaseName: string;
  diagnosisLikelihood: string;
  eppoCode: string;
  imageReferences: string[];
  pathogenClass: string;
  preventiveMeasures: string[];
  summaryDescription: string;
  symptoms: string;
  treatmentChemical: string;
  treatmentOrganic: string;
  triggerText: string;
  contentsYn: boolean;
};

export type DiseaseAnalysisMonthly = {
  ncpmsId: number;
  imgUrl: string;
  cautionDate: string;
  cropName: string;
  cropKorName: string;
  description: string;
  diseaseName: string;
};

type ResponsePresignedUrl = {
  presignedUrl: string;
  cdnUrl: string;
  signatureDurationMinute: number;
  uploadAllowedExtension: string;
};

type ERPAddress = {
  district_1: string;
  district_2: string;
  district_3: string;
  latitude: number;
  longitude: number;
};

export type Product = {
  id: number;
  productNumber: string;
  brandName: string;
  price: number | null;
  rating: number | null;
  imgUrl: string;
};

export enum SATISFACTION {
  GOOD = "GOOD",
  BAD = "BAD",
  DONT_KNOW = "DONT_KNOW",
}

// 모니터링 지표
// 관련 링크 & 히스토리는 아래 "작물 질병 분석 접근이력" 참조
export enum INDICATORS {
  // ----- 메인 화면 -----
  ENTER = "100", // 병해충 진단 메뉴 진입

  PREV_IMAGE_CLICK = "102", // 이전 진단 내역 이미지 클릭
  PREV_BTN_CLICK = "109", // 상단 헤더 이전 버튼 클릭

  DIAGNOSIS = "200", // 진단 실행 (plantix 호출) - 사진 촬영 버튼 클릭
  DIAGNOSIS_SUCCESS = "201", // 진단 결과 - 성공 (정상응답 - 정상응답의 에러 포함)

  DIAGNOSIS_RECAPTURE = "280", // 다시 촬영하기
  DIAGNOSIS_ERROR_FOCUS = "290",
  DIAGNOSIS_ERROR_BLUR = "291",
  DIAGNOSIS_ERROR_NOT_SUPPORT = "292",
  DIAGNOSIS_ERROR_NON_PLANT = "293",
  DIAGNOSIS_ERROR_UNKNOWN = "294",
  DIAGNOSIS_ERROR_BACKEND = "297",
  DIAGNOSIS_ERROR_ETC_400 = "298",
  DIAGNOSIS_ERROR_ETC = "299",

  // ----- 결과 화면 -----
  RESULT = "300", // 결과 페이지 진입
  RESULT_ALL = "301", // 결과 개수
  RESULT_NCPMS = "302", // 농진청 데이터 있는 결과 개수
  RESULT_NON_NCPMS = "303", // 농진청 데이터 없는 결과 개수
  RESULT_HEALTHY = "304", // 건강한 식물 개수

  SATISFACTION_GOOD = "501",
  SATISFACTION_BAD = "502",
  SATISFACTION_DONT_KNOW = "503",

  // ----- 상세 결과 화면 -----
  DETAIL = "400", // 상세 페이지 진입
  COMPARE_IMAGE = "401", // 이미지 클릭 - 이미지 비교 화면

  // ----- 추천 농약 리스트 -----
  PESTICIDE_LIST = "600", // 추천 농약 리스트 페이지 진입

  // ----- 농약 상세 정보 -----
  PESTICIDE_DETAIL = "700", // 농약 상세 정보 페이지 진입
  PESTICIDE_DETAIL_SITE = "701", // 사이트 바로가기 클릭
  PESTICIDE_DETAIL_COVERAGE = "702", // 적용 농작물 범위 보기 팝업 오픈
  PESTICIDE_DETAIL_HOWTOUSE = "703", // 사용방법 자세히보기 팝업 오픈

  // ----- v3.2.0 성과관리를 위한 로깅 코드  -----
  PRODUCT_SALE_SITE = "900", // 비료 상세 - 구매하기 클릭
  PESTICIDE_COMPANY_CALL_FROM_MAIN = "910", // 농약사 전화 연결 - 메인페이지에서
  PESTICIDE_COMPANY_CALL_FROM_DETAIL = "911", // 농약사 전화 연결 - 상세결과 페이지에서
  PESTICIDE_COMPANY_CALL_FROM_PESTICIDE_DETAIL = "912", // 농약사 전화 연결 - 농약 상세 정보 페이지에서
  SATISFACTION_SURVEY = "920", // 사용자 피드백 완료 버튼 클릭
  PRODUCT_DETAIL_PESTICIDE = "930", // 농약 상세 정보 진입시
  PRODUCT_DETAIL_FERTILIZER = "931", // 비료 상세 정보 진입시
}

/**
 * 작물 질병 분석 접근이력
 * 노션 - '모니터링 지표' 참조
 * https://www.notion.so/greenlabs/PRD-PoC-10a04958a4dc450298bfb10cdbce936c
 * 관련 지라
 * https://green-labs.atlassian.net/browse/MATA-34
 * 이벤트 지표 및 모니터링 지표 시트
 * https://docs.google.com/spreadsheets/d/1ta37PlikhpwOiOY20dmlFyI_dlVVcfq81klYJnBTZQY/edit#gid=129875381
 */
export async function logging(path: string | null, code: string, method: string | null, userKey: string) {
  const body = { path, code, method, userKey };
  const res = await request.post<string>(`/api/v2/crop/disease/analysis/accesslog`, body);
  return res.data;
}

/**
 * 농약 처방 접근이력
 * 노션 - 페이지 접근 이력 참조
 * https://www.notion.so/greenlabs/CROP_-v2-0-12ef891f6a6341f7b3ffb221e70de4e8#71a7a285784841e0a554c22270ae9fce
 * 관련 지라
 * https://green-labs.atlassian.net/browse/MATA-206
 */
export async function loggingPesticide(code: string, pesticideId: number | null, userKey: string) {
  const body = { code, pesticideId, userKey };
  const res = await request.post<string>(`/api/v1/crop/pesticide/accesslog`, body);
  return res.data;
}

/**
 * v3.2.0 성과 관리를 위한 로깅
 * 노션 - https://www.notion.so/greenlabs/CROP_-GPST-5bcc508815074c09ae4f7d1c5d99e102
 */
export async function loggingPM(code: string, json: string, userKey: string) {
  const body = { code, json, userKey };
  const res = await request.post<string>(`/api/v1/crop/products/accesslog`, body);
  return res.data;
}

const request = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  headers: {
    Authorization: `VhowsAK ${process.env.NEXT_PUBLIC_API_TOKEN}`,
  },
});

const request_facil = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  headers: {
    Authorization: `VhowsAK ${process.env.NEXT_PUBLIC_API_TOKEN_FACIL}`,
  },
});

/**
 * AWS S3 업로드 사전 링크 생성
 */
export async function getPresignedUrl(filename: string) {
  const param = { filetype: "crop-disease-img", filename };
  const res = await request.get<ResponsePresignedUrl>(
    `/api/v2/crop/disease/analysis/presignedurl?${qs.stringify(param)}`,
  );
  return res.data;
}

/**
 * AWS S3에 이미지 파일 업로드
 */
export async function uploadImageFile(presignedUrl: string, file: File) {
  const res = await axios.put(presignedUrl, file, {
    headers: {
      "content-type": file.type,
    },
  });
  return res.data;
}

/**
 * 병해충 진단 결과 검색
 */
export async function getDiseaseAnalysis(userKey: string, size: number = 6, page: number = 1, crops?: string[] | null) {
  const param = { userKey, page, size, crops };
  const res = await request.get<DiseaseAnalysis[]>(`/api/v2/crop/disease/analysis?${qs.stringify(param)}`);
  return res.data;
}

/**
 * ERP(우성/아산) 주소 조회
 */
export async function getERPAddress(uid: string, type: string) {
  const param = { type };
  const res = await request_facil.get<ERPAddress>(`/api/v2/companies/erp/${uid}/address?${qs.stringify(param)}`);
  return res.data;
}

/**
 * 시공사 전체 목록 조회
 * @param params
 * @param cancelToken
 */
export async function getCompanies(params?: any, cancelToken?: any) {
  let response;
  try {
    if (checkVersion("2.0.0", QA_FLAG_STAGING)) {
      params = {
        ...params,
        pageLimit: PAGE_LIMIT,
      };
      let _params = "";
      ["longitude", "latitude", "sort", "filter", "pageNum", "pageLimit"].forEach((i: any) => {
        if (i in params && params[i] !== undefined && params[i] !== null && params[i] !== "") {
          _params += `${i}=${JSON.stringify(params[i])}&`;
        }
      });
      _params = _params.slice(0, -1);
      _params = _params
        .replace(/\\/gi, encodeURIComponent("\\"))
        .replace(/\[/gi, encodeURIComponent("["))
        .replace(/\]/gi, encodeURIComponent("]"))
        .replace(/\{/gi, encodeURIComponent("{ "))
        .replace(/\}/gi, encodeURIComponent("}"));
      response = await request_facil.get(`/api/v2/companies?${_params}`, {
        cancelToken,
      });
    } else {
      response = await request.get(`/api/v1/facil/mediation/companies`, {
        params: {
          pageLimit: 12,
          ...params,
        },
        cancelToken,
      });
    }
    return response.data;
  } catch (e) {
    throw e;
  }
}

/**
 * 시공사 상세 조회
 */
export async function getCompany(id: string) {
  try {
    if (checkVersion("2.0.0", QA_FLAG_STAGING)) {
      const { data } = await request.get(`/api/v2/companies/${id}`);
      return data;
    } else {
      const { data } = await request.get(`/api/v1/facil/mediation/companies/${id}`);
      return data;
    }
  } catch (e) {
    throw e;
  }
}
/**
 * 작물 질병 분석 요청
 */
export async function diseaseAnalysis(
  cdnUrl: string,
  image: File,
  usedImageGallery: boolean,
  userKey: string,
  address?: CoordinateAddress,
  userType?: string,
) {
  const formData = new FormData();
  checkVersion("3.2.5", QA_FLAG_STAGING) ? formData.append("version", "2.1") : null;
  formData.append("imgUrl", cdnUrl);
  formData.append("image", image);
  formData.append("usedImageGallery", `${usedImageGallery}`);
  formData.append("userKey", userKey);
  if (address) {
    formData.append("district_1", address.district_1);
    formData.append("district_2", address.district_2);
    formData.append("district_3", address.district_3);
    formData.append("latitude", address.latitude.toString());
    formData.append("longitude", address.longitude.toString());
  }
  if (userType) formData.append("userType", userType);
  const res = await request.post<DiseaseAnalysis>(`/api/v2/crop/disease/analysis`, formData);
  return res.data;
}

/**
 * 작물 질병 분석 만족도 설정
 */
export async function setSatisfaction(analysisId: number, userKey: string, satisfaction: SATISFACTION) {
  const body = { satisfactionRating: satisfaction, userKey };
  const res = await request.put<void>(`/api/v2/crop/disease/analysis/${analysisId}/satisfaction`, body);
  return res.data;
}

/**
 * 병해충 진단 결과 ncpmsID 코드 업데이트
 */
export async function ncpmsIdUpdate(analysisId: number, id: number, ncpmsId: string, userKey: string) {
  const body = { ncpmsId, userKey };
  const res = await request.put<void>(`/api/v2/crop/disease/analysis/${analysisId}/predicted/diagnoses/${id}`, body);
  return res.data;
}

/**
 * 거리에 따른 병해충 결과 리스트 (위/경도) (지도페이지 상단)
 */
export async function diseaseListCoordinate(
  latitude: number,
  longitude: number,
  userKey: string,
  count: number,
  period: number | null,
  crops: string[] | null,
) {
  const param = { latitude, longitude, userKey, count, period, crops };
  const res = await request.get<Location[]>(`/api/v2/crop/disease/analysis/map/location?${qs.stringify(param)}`);
  return res.data;
}

/**
 * 거리에 따른 병해충 결과 리스트 (데이터) (지도페이지 하단)
 */
export async function diseaseListData(
  latitude: number,
  longitude: number,
  userKey: string,
  size: number,
  page: number,
  period: number | null,
  crops: string[] | null,
) {
  const param = { latitude, longitude, userKey, size, page, period, crops };
  const res = await request.get<DiseaseAnalysis[]>(`/api/v2/crop/disease/analysis/map/search?${qs.stringify(param)}`);
  return res.data;
}

/**
 * 다른 농민 병해충 진단 리스트 전체
 */
export async function diseaseListAll(
  userKey: string,
  size: number,
  page: number,
  sortOrder: "LATEST" | "DISTANCE" = "LATEST",
  crops: string[] | null,
  latitude: number,
  longitude: number,
) {
  const param = { userKey, size, page, sortOrder, crops, latitude, longitude };
  const res = await request.get<DiseaseAnalysis[]>(`/api/v2/crop/disease/analysis/all?${qs.stringify(param)}`);
  return res.data;
}

/**
 * 월별 주의해야할 작물 필터 리스트
 */
export async function monthWarningCropList(userKey: string, cautionDate: string) {
  const param = { userKey, cautionDate };
  if (checkVersion("3.2.3", QA_FLAG_STAGING)) {
    const res = await request.get<[]>(`/api/v1/crop/disease/ncpms/monthly/crops?${qs.stringify(param)}`);
    return res.data;
  } else {
    return null;
  }
}

/**
 * 월별 주의해야할 병해충 진단 리스트 전체
 */
export async function monthWarningDiseaseListAll(
  userKey: string,
  size: number,
  page: number,
  cautionDate: string,
  crops: string[] | null,
) {
  const param = { userKey, size, page, cautionDate, crops };
  const res = await request.get<DiseaseAnalysisMonthly[]>(`/api/v1/crop/disease/ncpms/monthly?${qs.stringify(param)}`);
  return res.data;
}

/**
 * 오늘 병해충 진단 건수
 */
export async function checkTodayCount() {
  const res = await request.get<number>(`/api/v2/crop/disease/analysis/today/count`);
  return res.data;
}

/**
 * 슬랙 알람
 */
export async function slackAlarm(id: number, userKey: string, ncpmsMappings?: any) {
  let body;
  if (checkVersion("1.0.2", QA_FLAG_STAGING)) {
    body = { userKey };
  } else {
    body = { userKey, ncpmsMappings };
  }
  const res = await request.post<string>(`/api/v2/crop/disease/analysis/${id}/slack`, body);
  return res.data;
}

/**
 * 농진청 ncpms정보 (자세히보기 유무)
 */
export async function ncpmsInfo(peatId: string, crop: string) {
  const param = { peatId, crop };
  let res;
  try {
    res = await request.get<number>(`/api/v1/crop/disease/ncpms/info?${qs.stringify(param)}`);
  } catch (e) {
    res = { error: e, data: -1 };
  }
  return res.data;
}

/**
 * 농진청 ncpms 상세 정보
 */
export async function ncpmsDetail(ncpmsId: number) {
  const res = await request.get<NcpmsDetailResponse>(`/api/v1/crop/disease/ncpms/${ncpmsId}`);
  return res.data;
}

/**
 * 결과 조회 (v3.2.0)
 */
export async function result(traceId: string, userKey?: string) {
  const param = { userKey };
  const res = await request.get<DiseaseAnalysis>(
    `/api/v2/crop/disease/analysis/results/${traceId}?${qs.stringify(param)}`,
  );
  return res.data;
}

/**
 * 결과 상세 조회 (v3.2.0)
 */
export async function resultDetail(traceId: string, predictedId: number, userKey: string) {
  const param = { userKey };
  let res: AxiosResponse<ResultDetail, any>;
  try {
    res = await request.get<ResultDetail>(
      `/api/v2/crop/disease/analysis/results/${traceId}/detail/${predictedId}?${qs.stringify(param)}`,
    );
  } catch (e) {
    // @ts-ignore
    res = { type: "" };
  }
  return res.data;
}

/**
 * 농약 미리보기 리스트
 */
export async function pesticideList(size: number, page: number, pestName: string, cropName: string) {
  const param = { size, page, pestName, cropName };
  let res: AxiosResponse<PesticidesList, any>;
  try {
    res = await request.get<PesticidesList>(`/api/v1/crop/pesticide?${qs.stringify(param)}`);
  } catch (e) {
    console.log("error: ", e);
    // @ts-ignore
    res = { data: [], totalCount: 0 };
  }
  return res.data;
}

/**
 * 농약 상세 정보
 */
export async function pesticideInfo(pesticideId: number) {
  const res = await request.get<PesticideInfo>(`/api/v1/crop/pesticide/${pesticideId}`);
  return res.data;
}

type GetProductParameter = {
  size?: number;
  page: number;
  pestName: string;
  cropName: string;
};

/**
 * 비료/영양제 리스트
 */
export async function getFertilizers({ size = 20, page, pestName, cropName = "" }: GetProductParameter) {
  const param = { size, page, pestName, cropName };
  let res: AxiosResponse<Fertilizers[], any>;
  try {
    res = await request.get<Fertilizers[]>(`/api/v1/crop/products?${qs.stringify(param)}`);
  } catch (e) {
    console.log("error: ", e);
    // @ts-ignore
    res = { data: [] };
  }
  return res.data;
}

/**
 * 비료/영양제 상세 정보
 */
export async function getFertilizerInfo(productNumber: string) {
  const res = await request.get<FertilizerInfo>(`/api/v1/crop/products/${productNumber}`);
  return res.data;
}

/**
 * 연관 작물 리스트
 */
export async function getRelatedCrops(peatId: string) {
  const param = { peatId };
  const res = await request.get<RelatedCrop[]>(`/api/v2/crop/disease/crops?${qs.stringify(param)}`);
  return res.data;
}

/**
 * 사용자 피드백 설정
 */
export async function setEvaluation(traceId: string, predictedId: number, satisfaction: Satisfaction) {
  const res = await request.put<string>(
    `/api/v2/crop/disease/analysis/results/${traceId}/detail/${predictedId}/evaluation`,
    satisfaction,
  );
  return res.data;
}
