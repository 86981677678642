import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { Addresses } from "./address-item";

type addressProps = {
  getAddress1: () => Promise<any>;
  getAddress2?: (add1: string) => Promise<any>;
  getAddress3?: (add1: string, add2: string) => Promise<any>;
  setAddressCallback: Function;
  region: any;
};

type MakeAddressParams = {
  setAddress: Dispatch<SetStateAction<object | null>>;
  getAddress: (add1?: any, add2?: any) => Promise<any>;
  add1?: string;
  add2?: string;
};

export function Address({ getAddress1, getAddress2, getAddress3, setAddressCallback, region }: addressProps) {
  const [address1, setAddress1] = useState<object | null>(null);
  const [address2, setAddress2] = useState<object | null>(null);
  const [address3, setAddress3] = useState<object | null>(null);

  const makeAddressList = async ({ setAddress, getAddress, add1, add2 }: MakeAddressParams) => {
    const data = await getAddress(add1, add2);
    setAddress(data);
  };

  useEffect(() => {
    makeAddressList({ setAddress: setAddress1, getAddress: getAddress1 });
  }, []);

  useEffect(() => {
    if (region.address2 && getAddress3) {
      makeAddressList({
        setAddress: setAddress3,
        getAddress: getAddress3,
        add1: region.address1,
        add2: region.address2,
      });
    }
  }, [region.address2]);

  useEffect(() => {
    if (region.address1 && getAddress2) {
      makeAddressList({ setAddress: setAddress2, getAddress: getAddress2, add1: region.address1 });
    }
  }, [region.address1]);

  return (
    <>
      {/*주소 선택, 목록 영역*/}
      <Addresses
        buttonId="address1-button"
        addressWrapperId="address1-wrapper"
        addresses={address1}
        addressName="address1"
        setItemClick={setAddressCallback}
        region={region.address1}
        regionExistClassNames="rounded-t-[10px] border-t"
        regionNonExistClassNames=""
        placeholder="시・도를 선택해주세요"
      />

      {region.address1 && getAddress2 && (
        <Addresses
          buttonId="address2-button"
          addressWrapperId="address2-wrapper"
          addresses={address2}
          addressName="address2"
          setItemClick={setAddressCallback}
          region={region.address2}
          regionExistClassNames={!getAddress3 ? "rounded-b-[10px] border-b" : ""}
          regionNonExistClassNames="bg-white"
          placeholder="시・군・구를 선택해주세요"
        />
      )}

      {region.address2 && getAddress3 && (
        <Addresses
          buttonId="address3-button"
          addressWrapperId="address3-wrapper"
          addresses={address3}
          addressName="address3"
          setItemClick={setAddressCallback}
          region={region.address3}
          regionExistClassNames="rounded-b-[10px] border-b"
          regionNonExistClassNames="rounded-xl"
          placeholder="읍・면・동을 선택해주세요"
        />
      )}
    </>
  );
}
