import { checkVersion, QA_FLAG_DEV, QA_FLAG_STAGING } from "@vhows/util";

export const COMMON = {
  CURRENT_PLANTIX: "current_plantix",
  CURRENT_NCPMS: "current_ncpms",
  TEMPORARY_ID: "tid",
  USER_ID: "uid",
  FARMMORNING_ACCESS_TOKEN: "fm-at",
  FARMMORNING_REFRESH_TOKEN: "fm-rt",
};

export const PESTICIDE_DETAIL_INFO = [
  "대상 작물",
  "적용 병해충",
  "사용 적기",
  "사용 방법",
  "안전 사용 기준",
  "희석 배수",
  "인축독성",
  "어독성",
  "주성분",
  "작용기작",
  "제형",
];

export const FERTILIZER_DETAIL_INFO = [
  "대상 작물",
  "적용 이유",
  "사용 적기",
  "사용 방법",
  "희석 배수",
  "주성분",
  "비고",
  "사용 효과",
  "특징",
  "주의사항",
];

export const REGEXP = {
  ABSOLUTE_URL_PATTERN: /https?:\/\//g,
};

export const ERROR = {
  NO_DETAIL_INFO: "NO_DETAIL_INFO",
};

export const PRODUCT = {
  PESTICIDE: "pesticide",
  FERTILIZER: "fertilizer",
};

/** v3.2.0 부터 변경되는 명칭 */
export const CROP_NAME = checkVersion("3.2.0", QA_FLAG_STAGING) ? "cropsName" : "cropsKorName";

/** 탭 및 사용자 피드백용 카테고리 (상세 페이지에서 탭 구성시에 생성) */
export const CATEGORY = {
  DIAGNOSIS_ACCURACY: "diagnosisAccuracy", // 진단 정확도
  CAUSES_TEXT: "causesText", // 발생 원인
  SYMPOTOMS: "symptoms", // 증상 설명
  RELATED_CROPS: "relatedCrops", // 연관 작물
  PREVENTION_METHOD: "preventionMethod", // 방제 방법
  PREVENTION_MEASURES: "preventiveMeasures", // 예방 조치
  ECOLOGY_INFO: "ecologyInfo", // 생태 정보
  STYLE_INFO: "styleInfo", // 형태 정보
  WRONGED_CROP: "wrongedCrop", // 가해 식물
  DAMAGE_INFO: "damageInfo", // 피해 정보
  VIRUS_CHARACTERISTIC: "virusCharacteristic", // 특징
  RECOMANDED_FERTILIZERS: "recomandedFertilizers", // 추천 비료
  RECOMANDED_PESTICIDES: "recomandedPesticides", // 추천 농약
  PESTICIDE_COMPANY: "pesicideCompany", // 내 주변 농약사
  TRANSFORM_KEY: {
    diagnosisAccuracy: "진단 정확도",
    "진단 정확도": "diagnosisAccuracy",
    causesText: "발생 원인",
    "발생 원인": "causesText",
    symptoms: "증상 설명",
    "증상 설명": "symptoms",
    relatedCrops: "연관 작물",
    "연관 작물": "relatedCrops",
    preventionMethod: "방제 방법",
    "방제 방법": "preventionMethod",
    preventiveMeasures: "예방 조치",
    "예방 조치": "preventiveMeasures",
    ecologyInfo: "생태 정보",
    "생태 정보": "ecologyInfo",
    styleInfo: "형태 정보",
    "형태 정보": "styleInfo",
    wrongedCrop: "가해 식물",
    "가해 식물": "wrongedCrop",
    damageInfo: "피해 정보",
    "피해 정보": "damageInfo",
    virusCharacteristic: "특징",
    특징: "virusCharacteristic",
    recomandedFertilizers: "추천 비료",
    "추천 비료": "recomandedFertilizers",
    recomandedPesticides: "추천 농약",
    "추천 농약": "recomandedPesticides",
    pesicideCompany: "내 주변 농약사",
    "내 주변 농약사": "pesicideCompany",
  },
};

export const ENTRY_TYPE = {
  MONTH_WARNING: "month-warning",
  DETAIL: "detail",
  PESTICIDE: "pesticide",
};

export const PRODUCT_BTN_TYPE = {
  COVERAGE: "coverage",
  HOWTOUSE: "howToUse",
};
