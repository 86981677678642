import "styles/globals.css";
import "react-spring-bottom-sheet/dist/style.css";
import type { AppProps } from "next/app";
import { useAtomsDebugValue } from "jotai-devtools";
import Layout from "components/layout";
import ErrorBoundary from "@vhows/util/error-boundary";
import { ErrorPage } from "ui/components/error";
import { useRouter } from "next/router";

export type PageProps = any;

function DebugAtoms() {
  useAtomsDebugValue();
  return null;
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  return (
    <>
      <DebugAtoms />
      <Layout>
        <ErrorBoundary key={router.pathname} fallback={<ErrorPage />}>
          <Component {...pageProps} />
        </ErrorBoundary>
      </Layout>
    </>
  );
}

export default MyApp;
