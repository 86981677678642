import { BookOpenIcon } from "@heroicons/react/24/outline";

type TitleBox = {
  /**
   * 타이틀 박스 텍스트
   */
  text: string;
  /**
   * 배경 색상
   * (bg-[#ABCDEF] 또는 bg-blue-50 형태로 전달)
   */
  bgColor?: string;
  /**
   * 텍스트 및 아이콘 색상
   * (text-[#ABCDEF] 또는 text-blue-50 형태로 전달)
   */
  textColor?: string;
  /**
   * 아이콘 컴포넌트 형태로 전달 (JSX.Element)
   */
  icon?: JSX.Element;
};

export const TitleBox = ({ bgColor = "bg-[#EDFDEB]", textColor = "text-green-500", text = "", icon }: TitleBox) => {
  return (
    <>
      <div className="mt-3 w-full">
        <div className={`flex flex-wrap rounded-md py-2.5 px-4 ${bgColor} ${textColor}`}>
          <div className="flex justify-center">{icon ? icon : <BookOpenIcon className={`h-6 w-6 ${textColor}`} />}</div>
          <div className="flex flex-col pl-2.5">
            <div className={`flex-1 text-base font-bold`}>{text}</div>
          </div>
          <div className="flex-1"></div>
        </div>
      </div>
    </>
  );
};
