import Header from "./index";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

const HeaderV1 = ({ title, bodyScroll, callBacks }: Header) => {
  return (
    <>
      <div
        className={`fixed z-20 m-auto flex h-14 w-full max-w-xl bg-white ${
          !bodyScroll ? "border-b border-b-neutral-300" : ""
        }`}
      >
        <a
          className="h-14 w-14 cursor-pointer p-3 text-center"
          id="backButton"
          onClick={(e: any) => {
            callBacks(e, "header");
          }}
        >
          <ChevronLeftIcon className="h-8 w-8 text-gray-900" />
        </a>
        <div className="w-full self-center pr-14 text-center text-xl font-bold">{title}</div>
      </div>
    </>
  );
};

export default HeaderV1;
